import Bugsnag from '@bugsnag/js';
import $ from './jquery';

const valueOrDefault = (object, key, defaultValue) => {
  if (object === undefined)
    return defaultValue;

  const keySegments = (key || '').split('.').filter((k) => k.length);

  let value = object;

  for (let i = 0; i < keySegments.length; i++)
    if (typeof value === 'object')
      value = value[keySegments[i]];
    else {
      value = undefined;
      break;
    }

  return value !== null && value !== undefined ? value : defaultValue;
};

/**
 * This class holds the JSON body of the response to a successful
 * AJAX request.
 */
export class AjaxResponse {
  constructor (value) {
    this.value = value;
  }

  /**
   * Returns a new AjaxResponse object holding the value at the provided
   * key in the parent's value.
   *
   * @returns {AjaxResponse}
   */
  childAt (key) {
    return new AjaxResponse(this.getAt(key));
  }

  /**
   * Returns the value at the provided key if it exists (not null,
   * not undefined), or the provided default value otherwise.
   */
  getAt (key, defaultValue) {
    return valueOrDefault(this.value, key, defaultValue);
  }

  /**
   * Returns the value of this AjaxResponse object if it exists (not null,
   * not undefined), or the provided default value otherwise.
   */
  get (defaultValue) {
    return valueOrDefault(this.value, null, defaultValue);
  }

  /**
   * Returns true if this AjaxResponse is containing a value at the provided
   * key. Returns false either because the provided key doesn't exist, or
   * because the value at that key is null or undefined.
   */
  contains (key) {
    return this.value !== null &&
      this.value !== undefined &&
      this.value[key] !== null &&
      this.value[key] !== undefined;
  }
}

/**
 * This class holds information about an error related to an
 * AJAX request.
 */
export class AjaxError {
  /**
   * @param message An error message
   * @param code A code identifying the kind of error
   * @param statusCode An HTTP status code
   */
  constructor (message, code, statusCode) {
    this.message = message;
    this.code = code;
    this.statusCode = statusCode;
  }

  /**
   * Returns the error message
   */
  getMessage (defaultMessage) {
    return valueOrDefault(this, 'message', defaultMessage);
  }

  /**
   * Returns true if the status code is in the range of HTTP client
   * errors (between 400 and 499), false otherwise.
   */
  isClientError () {
    return this.statusCode >= 400 && this.statusCode <= 499;
  }

  /**
   * Returns true if the status code is in the range of HTTP service
   * unavailable error (503), false otherwise.
   */
  isServiceUnavailable () {
    return this.statusCode === 503;
  }
}

/**
 * Sends an AJAX request to the provided URL. By default, it sends the
 * request using the HTTP GET method without body.
 *
 * Optionally, you can set a different HTTP method and provide a body to
 * be included in the request.
 *
 * Returns a rejected promise containing an AjaxError object either if the
 * server responds with a 4xx or a 5xx HTTP status code, or if it encodes
 * an error inside a 2xx response.
 *
 * Returns a resolved promise containing an AjaxResponse object if the
 * request succeeds.
 *
 * @returns {Promise}
 */
export default (url, body = '', method = 'get', dataType = 'json') => {
  if (Bugsnag._client)
    Bugsnag.leaveBreadcrumb('XMLHttpRequest started', { url, method });

  return new Promise((resolve, reject) => $
    .ajax({
      url,
      dataType,
      data: body,
      type: method.toLowerCase(),
      beforeSend (xhr) {
        if (['post', 'delete', 'put'].indexOf(method.toLowerCase()) !== -1)
          xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'));
      }
    })
    .done((json, textStatus, jqXHR) => {
      if (json !== undefined && json.error !== undefined && json.error.message !== undefined)
        reject(new AjaxError(json.error.message, json.error.code, jqXHR.status));
      else
        resolve(new AjaxResponse(json));
    })
    .fail(jqXHR => {
      reject(new AjaxError(jqXHR?.responseJSON, null, jqXHR.status));
    }));
};
